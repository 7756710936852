<template>
  <div class="thank">
    <div class="thank-wrapper mt-5">
      <h1>Thank you</h1>
      <p>Thanks for reaching out! We will be in touch with you soon!</p>
      <!-- <div class="button2" @click="$router.push('/')">Back to homepage</div> -->
      <div class="socmed-handle d-flex flex-wrap justify-content-center">
        <div class="socmed-icons mb-3">
          <h6>Follow us</h6>
          <div class="socmed-icons-wrapper">
            <a href="https://www.facebook.com/tekkismy/" target="_blank"
              ><img src="@/assets/img/social/fb.png" alt=""
            /></a>
            <a href="https://www.instagram.com/tekkismy/" target="_blank"
              ><img src="@/assets/img/social/ig.png" alt=""
            /></a>
            <a href="https://my.linkedin.com/company/tekkismy" target="_blank"
              ><img src="@/assets/img/social/linkedin.png" alt=""
            /></a>
          </div>
        </div>
        <div class="homepage-button mb-3">
          <h6>Visit our website</h6>
          <div class="homepage-button-wrapper" @click="$router.push('/')">
            Let's go
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixpanel from "mixpanel-browser";

export default {
  name: "ThankYou",
  metaInfo() {
    return {
      title: "Thank You - Contact Form",
      meta: [
        {
          name: "Thank You - Contact Form",
        },
        {
          property: "og:title",
          content: "Thank You - Contact Form",
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },
  mounted() {
    mixpanel.track_pageview({ page: "Thank you" });
  },
};
</script>

<style lang="scss" scoped>
.thank {
  background-image: url("../assets/img/dots.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 36px;
  font-weight: 500;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  line-height: 50px;
}
.button2 {
  width: fit-content;
  line-height: 25px;
  margin-bottom: 1rem;
  margin: 0 auto;
}
p {
  margin-bottom: 1.25rem;
  color: #8a8a8a;
  font-size: 20px;
}
h3 {
  color: #4f4f4f;
  margin-bottom: 1rem;
}
.socmed-icons-wrapper {
  background-color: #5c52ca;
  padding: 0.25rem 1rem;
  width: fit-content;
  border-radius: 10px;
  color: #fefefe;
  margin: 0 auto;
  img {
    width: 40px;
    height: auto;
    vertical-align: top;
    padding: 0.5rem;
  }
}
.socmed-handle {
  margin: auto 0;
}
.socmed-icons,
.homepage-button {
  background-color: #fefefe;
  width: 270px;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.05);
}
.homepage-button-wrapper {
  height: 49px;
  background-color: #5c52ca;
  border-radius: 10px;
  color: #fefefe;
  line-height: 49px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 65px;
  }
  .socmed-handle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
  }
  .socmed-icons {
    margin-right: 2rem;
  }
}
</style>
